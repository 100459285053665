import {
  MailIcon,
  PhoneCallIcon,
  PinPointIcon,
  WhatsappIcon,
} from 'assets/images/svg';
import Text from 'components/commons/text';
// import Button from 'components/elements/button';
// import TextField from 'components/elements/text-field';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { CSS, VariantProps } from 'styles';
import color from 'styles/color';
// import typography from 'styles/typography';

import {
  ALink,
  ContactUsItem,
  ContentSection,
  ImageContainer,
  ImageContent,
  StyledContent,
  StyledCopyright,
  StyledFooter,
  StyledFooterContainer,
  // StyledInformationContainer,
  // StyledLeftSideInformationContainer,
  // StyledRightSideInformationContainer,
  styles,
} from './style';

type CssProps = { css?: CSS };
type FooterVariants = VariantProps<typeof StyledFooter>;
type FooterProps = React.HTMLAttributes<HTMLElement>;
export type IFooterProps = FooterProps & CssProps & FooterVariants;

const today = new Date();

const Footer = React.forwardRef<HTMLElement, FooterProps>((props, ref) => {
  const { t } = useTranslation();
  const { ...rest } = props;

  const { locale } = useRouter();

  const menuList = React.useMemo<
    {
      text: string;
      href: string;
    }[]
  >(
    () => [
      {
        text: t('common:home'),
        href: `/${locale}`,
      },
      {
        text: t('common:check_booking'),
        href: `/${locale}/check-booking`,
      },
      {
        text: t('common:tnc'),
        href: `/${locale}/term-and-condition`,
      },
      {
        text: t('common:about_us'),
        href: `/${locale}/about-us`,
      },
    ],
    [locale, t],
  );

  const contactUsList = React.useMemo<
    { icon: React.ReactNode; text: string }[]
  >(
    () => [
      {
        icon: <PinPointIcon size={24} color={color.white} />,
        text: t('common:wjl_address'),
      },
      {
        icon: <PhoneCallIcon size={24} color={color.white} />,
        text: '0811-656-0227<br/> 0811-657-0227',
      },
      {
        icon: <WhatsappIcon size={24} color={color.white} />,
        text: '0811 6000 227 (Whatsapp Only)',
      },
      {
        icon: <MailIcon size={24} color={color.white} />,
        text: 'wjlcare@gmail.com',
      },
    ],
    [t],
  );

  return (
    <StyledFooter ref={ref} {...rest}>
      <StyledFooterContainer>
        <StyledContent>
          <ContentSection>
            <div className={styles.logoBig()}>
              <Image
                src="/assets/WJL-logo-long-white.svg"
                width={300}
                height={30}
                layout="intrinsic"
                alt="logo-dashboard"
              />
            </div>
            <div className={styles.logoSmall()}>
              <Image
                src="/assets/WJL-logo-long-white.svg"
                width={240}
                height={24}
                layout="intrinsic"
                alt="logo-dashboard"
              />
            </div>
            <Text css={{ my: 16 }} variant="body3">
              {t('common:wjl_about_us_short')}
            </Text>

            <div>
              <Text variant="headline6" css={{ mb: 16 }}>
                {t('common:payment_method')}
              </Text>
              <ImageContainer>
                <ImageContent>
                  <Image
                    alt="alfamart"
                    src="/assets/alfamart-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="indomaret"
                    src="/assets/indomaret-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="bni"
                    src="/assets/bni-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="bri"
                    src="/assets/bri-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="mandiri"
                    src="/assets/mandiri-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="permata"
                    src="/assets/permata-logo.png"
                    width={156}
                    height={55}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                {/* <ImageContent>
                  <Image
                    alt="bni"
                    src="/assets/bni-logo.png"
                    width={96}
                    height={29}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="bri"
                    src="/assets/bri-logo.png"
                    width={120}
                    height={26}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent>
                <ImageContent>
                  <Image
                    alt="bri"
                    src="/assets/permata-logo.png"
                    width={116}
                    height={24}
                    style={{ objectFit: 'contain' }}
                  />
                </ImageContent> */}
              </ImageContainer>
            </div>
          </ContentSection>
          <ContentSection css={{ '& > *:not(:first-child)': { mt: 16 } }}>
            <Text variant="headline5">{t('common:navigation')}</Text>
            {menuList.map((menu) => (
              <ALink href={menu.href} key={menu.href}>
                {menu.text}
              </ALink>
            ))}
          </ContentSection>
          <ContentSection css={{ '& > *:not(:first-child)': { mt: 16 } }}>
            <Text variant="headline5">{t('common:contact_us')}</Text>
            <Text variant="headline5" css={{ mt: 16 }}>
              {t('common:wjl_full_name')}
            </Text>
            {contactUsList.map((item, idx) => (
              <ContactUsItem key={item.text}>
                <div>{item.icon}</div>{' '}
                <Text
                  variant="body3"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </ContactUsItem>
            ))}
          </ContentSection>
        </StyledContent>
      </StyledFooterContainer>
      <StyledCopyright>
        <Text variant="body4" color="primary">
          {t('common:copyright_text', {
            year: today.getFullYear().toString(),
          })}
        </Text>
      </StyledCopyright>
    </StyledFooter>
  );
});

Footer.displayName = 'Footer';

export default Footer;
