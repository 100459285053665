import {
  NotificationModel,
  NotificationTypeEnum,
} from 'api-hooks/notification/model';
import {
  BellRingingIcon,
  RefundIcon,
  RefundRejectedIcon,
  ShipDelayIcon,
  StatusFailedIcon,
  StatusSuccessIcon,
  StatusWaitingIcon,
  VoyageTransferIcon,
} from 'assets/images/svg';
import { distanceToNow } from 'common/utils/date';
import Text from 'components/commons/text';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import typography from 'styles/typography';

import {
  ANotification,
  StyledHeaderNotification,
  StyledNotification,
  StyledRightSideNotification,
} from './style';

/* interface Props {
  type: NotificationTypeEnum | string;
  title: string;
  text: string;
  time: Date;
}
 */

interface Props {
  data: NotificationModel;
}
export default function Notification(props: Props) {
  const { createdAt, data } = props.data;
  const { locale = 'id' } = useRouter();
  const Icon = React.useCallback(() => {
    switch (data.type) {
      case NotificationTypeEnum.REMINDER:
        return <BellRingingIcon size={24} />;
      case NotificationTypeEnum.PAYMENT_FAILED:
        return <StatusFailedIcon size={24} />;
      case NotificationTypeEnum.PAYMENT_PENDING:
      case NotificationTypeEnum.REFUND_PENDING:
        return <StatusWaitingIcon size={24} />;
      case NotificationTypeEnum.DELAY:
        return <ShipDelayIcon size={24} />;
      case NotificationTypeEnum.PAYMENT_SUCCESS:
        return <StatusSuccessIcon size={24} />;
      case NotificationTypeEnum.REFUND_ACCEPTED:
        return <RefundIcon size={24} />;
      case NotificationTypeEnum.REFUND_REJECTED:
        return <RefundRejectedIcon size={24} />;
      case NotificationTypeEnum.TRANSFER:
        return <VoyageTransferIcon size={24} />;
      default:
        return <></>;
    }
  }, [data.type]);

  const linkStyles: React.CSSProperties = {
    textDecoration: 'none',
  };

  return (
    <Link href={data.url || '/'} passHref style={linkStyles}>
      <ANotification>
        <StyledNotification css={{ cursor: data.url ? 'pointer' : 'auto' }}>
          <Icon />
          <StyledRightSideNotification>
            <StyledHeaderNotification>
              <Text variant="headline7" css={{ '@md': typography.headline6 }}>
                {data.subject[locale]}
              </Text>
              <Text variant="body6" color="typeGray">
                {distanceToNow(createdAt, locale)}
              </Text>
            </StyledHeaderNotification>
            <Text variant="body5">{data.body[locale]}</Text>
          </StyledRightSideNotification>
        </StyledNotification>
      </ANotification>
    </Link>
  );
}
