import Text from 'components/commons/text';
import * as React from 'react';
import color from 'styles/color';

interface Props {}

export default function Maintainance(props: Props) {
  return (
    <>
      <div
        style={{
          minWidth: '100dvw',
          maxWidth: '100dvw',
          minHeight: '100dvh',
          maxHeight: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src="/assets/wirajaya-icon.svg"
          width={126}
          style={{ marginLeft: -32 }}
        />
        <img
          src="/assets/maintainance-left.svg"
          style={{
            position: 'fixed',
            left: 16,
            top: 36,
            zIndex: -1,
            height: 96,
          }}
        />
        <img
          src="/assets/maintainance-right.svg"
          style={{
            position: 'fixed',
            right: 16,
            top: 36,
            zIndex: -1,
            height: 240,
          }}
        />
        <img
          src="/assets/water.svg"
          style={{
            width: '100%',
            aspectRatio: '4:1',
            position: 'fixed',
            bottom: 0,
          }}
        />

        <div style={{ backgroundColor: color.white }}>
          <Text variant="body3" align="center" color="typeGray">
            Dikarenakan adanya
          </Text>
          <Text variant="body3Semibold" align="center" color="primary">
            Kegiatan Peningkatan Kualitas Layanan
          </Text>
          <Text variant="body3" align="center" color="typeGray">
            yang adakan dilakukan pada
          </Text>
        </div>

        <div>
          <Text variant="headline4" align="center" color="primary">
            Kamis, 13 Februari 2025
          </Text>
          <Text variant="headline4" align="center" color="primary">
            13:50 - 14:30 WIB
          </Text>
        </div>
        <Text variant="body3" align="center" color="typeGray">
          Layanan Pemesanan Tiket Online WJL tidak dapat digunakan selama
          kegiatan berlangsung
        </Text>
        <Text variant="headline6" align="center" color="primary">
          MOHON MAAF ATAS KETIDAKNYAMANANNYA
        </Text>
      </div>
    </>
  );
}
