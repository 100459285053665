import * as React from 'react';
import { styled, CSS, VariantProps } from 'styles';
import typography from 'styles/typography';

const DEFAULT_TAG = 'span';

const StyledText = styled(DEFAULT_TAG, {
  // Reset
  lineHeight: '1',
  margin: '0',
  fontWeight: '$regular',
  fontVariantNumeric: 'tabular-nums',
  display: 'block',
  color: '$defaultFont',

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    size: {
      xxs: {
        fontSize: '$xxs',
      },
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
      xl: {
        fontSize: '$xl',
      },
      xxl: {
        fontSize: '$xxl',
      },
    },
    variant: {
      ...typography,
      // headline1: typography.headline1,
      // headline2: typography.headline2,
      // headline3: typography.headline3,
      // headline4: typography.headline4,
      // headline5: typography.headline5,
      // headline6: typography.headline6,
      // headline7: typography.headline7,

      // body1: typography.body1,
      // body2: typography.body2,
      // body3: typography.body3,
      // body4: typography.body4,
      // body5: typography.body5,
      // body6: typography.body6,

      // buttonExtraSmall: typography.buttonExtraSmall,
      // buttonSmall: typography.buttonSmall,
      // buttonMedium: typography.buttonMedium,
      // buttonNormal: typography.buttonNormal,
      // buttonLarge: typography.buttonLarge,

      noVariant: {},
    },
    color: {
      primary: {
        color: '#104FA6',
      },
      primary2: {
        color: '#1565D6',
      },
      primary3: {
        color: '#327FEB',
      },
      primary4: {
        color: '#619CF0',
      },
      primary5: {
        color: '#8FB9F4',
      },
      primary6: {
        color: '#BED6F9',
      },
      secondary: {
        color: '#F6F7F8',
      },
      secondary2: {
        color: '#F8F9F9',
      },
      disabled: {
        color: '#8F9596',
      },
      disabledBg: {
        color: '#EAEAEB',
      },
      error: {
        color: '#F10C0C',
      },
      errorBg: {
        color: '#FEECEC',
      },
      info: {
        color: '#1890FF',
      },
      infoBg: {
        color: '#ECF6FF',
      },
      success: {
        color: '#00C800',
      },
      successBg: {
        color: '#EBFBEB',
      },
      warning: {
        color: '#654B08',
      },
      warningBg: {
        color: '#FDFBED',
      },
      disabledOverlay: {
        color: '#8F959614',
      },
      errorOverlay: {
        color: '#f10c0c14',
      },
      infoOverlay: {
        color: '#1890FF14',
      },
      successOverlay: {
        color: '#00c80014',
      },
      warningOverlay: {
        color: '#efd44d14',
      },
      overlayDark: {
        color: '#4f555833',
      },
      overlayLight: {
        color: '#ffffff80',
      },
      neutral1: {
        color: '#F3F3F4',
      },
      neutral2: {
        color: '#C9CBCF',
      },
      neutral3: {
        color: '#AEB1B7',
      },
      neutral4: {
        color: '#93979F',
      },
      neutral5: {
        color: '#787D87',
      },
      neutral6: {
        color: '#60646C',
      },
      neutral7: {
        color: '#484B51',
      },
      neutral8: {
        color: '#303236',
      },
      dbGray1: {
        color: '#D8DBDF',
      },
      dbGray2: {
        color: '#86898A',
      },
      dbBlack: {
        color: '#303132',
      },
      typeBlack: {
        color: '#171C22',
      },
      typeGray: {
        color: '#6F797B',
      },
      typeLightGrey: {
        color: '#C9CCCF',
      },
      typeDisabled: {
        color: '#8F9596',
      },
      typeDisabled2: {
        color: '#E2E4E8',
      },
      white: {
        color: '#FFFFFF',
      },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type TextProps = React.HTMLAttributes<HTMLSpanElement>;
type TextCSSProp = { css?: CSS };
type TextVariants = VariantProps<typeof StyledText>;
export type ITextProps = TextProps & TextCSSProp & TextVariants;

const Text = React.forwardRef<HTMLSpanElement, ITextProps>((props, ref) => {
  return <StyledText {...props} ref={ref} />;
});

Text.displayName = 'Text';

export default Text;
